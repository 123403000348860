import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './NavButton.module.scss';

interface Props {
	width?: number;
	className?: string;
	onClick?: () => void;
	children?: ReactNode;
}

const NavButton = ({ width, className, onClick, children }: Props) => {
	return (
		<button
			className={cx(styles.wrapper, className)}
			style={{ width }}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default NavButton;
