import { forwardRef, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Layout.module.scss';
import { Nav } from '../Nav';

interface Props {
	styles?: string;
	children: ReactNode;
}

const Layout = forwardRef<HTMLDivElement, Props>(
	({ styles: styleProp, children }, ref) => {
		return (
			<div ref={ref} className={cx(styleProp, styles.wrapper)}>
				<Nav />
				{children}
			</div>
		);
	}
);

export default Layout;
