/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components';
import { useClaim } from './hooks';
import useStore from './store';
import { getQueryParam } from './utils';
import {
	MarketingView,
	NotFoundView,
	ClaimView,
	TeaserView,
	ThankyouView,
	EligibilityView,
	FaqView,
	UnclaimedView,
} from './views';

let askedForAllowance = false;

function App() {
	// const { getAllowance } = useClaim();

	const claimStarted = useStore(state => state.claimStarted);

	useEffect(() => {
		let aeToken = getQueryParam('aeToken');
		if (aeToken !== '') {
			localStorage.setItem('aeToken', aeToken);
			const url = new URL(window.location.href);
			url.search = ''; // Remove the query string from the URL
			window.history.pushState({}, '', url.href);
		} else {
			aeToken = localStorage.getItem('aeToken') ?? '';
		}

		// if (aeToken) getAllowance();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (claimStarted && !askedForAllowance) {
	// 		askedForAllowance = true;
	// 		getAllowance();
	// 	}
	// }, [claimStarted, getAllowance]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<TeaserView />} />
				{/* <Route path="/token-story" element={<TeaserView />} /> */}
				{/* <Route path="/eligibility" element={<EligibilityView />} /> */}
				{/* <Route path="/unclaimed" element={<UnclaimedView />} /> */}
				<Route path="/faq" element={<FaqView />} />
				{/* <Route element={<ProtectedRoute />}>
					<Route path="/claim" element={<ClaimView />} />
					<Route path="/thankyou" element={<ThankyouView />} />
				</Route> */}
				<Route path="*" element={<NotFoundView />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
