import styles from '../SocialButton.module.scss';

const LinktreeSvg = () => {
	return (
		<svg
			width="17"
			height="21"
			viewBox="0 0 17 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={styles.svgHoverFill}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.89795 13.915H9.86769V20.5894H6.89795V13.915Z"
				fill="CurrentColor"
			/>
			<path
				className={styles.svgHoverFill}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.0314059 7.08228H5.07771L1.48487 3.68165L3.4647 1.66414L6.88292 5.16024V0.185547H9.85266V5.15899L13.2696 1.66288L15.2495 3.6804L11.6566 7.08103H16.7042V9.89374H11.6252L15.2344 13.3898L13.2545 15.3609L8.35146 10.4503L3.44837 15.3609L1.46854 13.3898L5.07771 9.89374H0V7.08228H0.0314059Z"
				fill="#9B9EFF"
			/>
		</svg>
	);
};

export default LinktreeSvg;
