import styles from '../SocialButton.module.scss';

const DiscordSvg = () => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_7_3856)">
				<path
					className={styles.svgHoverFill}
					d="M7.44668 8.06962C7.20785 8.08926 6.98515 8.19802 6.82279 8.37428C6.66044 8.55053 6.57031 8.78141 6.57031 9.02104C6.57031 9.26068 6.66044 9.49156 6.82279 9.66781C6.98515 9.84407 7.20785 9.95282 7.44668 9.97247C7.56687 9.96807 7.685 9.9399 7.79424 9.8896C7.90348 9.8393 8.00168 9.76786 8.08317 9.6794C8.16465 9.59094 8.22781 9.48722 8.26899 9.37422C8.31017 9.26122 8.32857 9.14119 8.32311 9.02104C8.329 8.9008 8.31091 8.78058 8.26989 8.66739C8.22887 8.55421 8.16573 8.45032 8.08417 8.36177C8.0026 8.27322 7.90423 8.20179 7.79478 8.15163C7.68534 8.10147 7.56701 8.07359 7.44668 8.06962ZM10.5753 8.06962C10.3838 8.05387 10.192 8.09629 10.025 8.19131C9.85794 8.28633 9.7235 8.42953 9.63919 8.60219C9.55489 8.77486 9.52465 8.96894 9.55243 9.15907C9.58021 9.3492 9.66471 9.52652 9.7949 9.66784C9.92508 9.80917 10.0949 9.90792 10.2821 9.95118C10.4693 9.99444 10.6652 9.98021 10.8442 9.91033C11.0232 9.84045 11.1769 9.71819 11.2853 9.55953C11.3937 9.40087 11.4517 9.21319 11.4517 9.02104C11.4571 8.90107 11.4388 8.78121 11.3978 8.66835C11.3567 8.55548 11.2938 8.45186 11.2125 8.36343C11.1312 8.275 11.0333 8.20351 10.9243 8.15309C10.8153 8.10266 10.6974 8.0743 10.5774 8.06962H10.5753Z"
					fill="CurrentColor"
				/>
				<path
					className={styles.svgHoverFill}
					d="M14.7429 0.878418H3.25715C3.02566 0.87898 2.79655 0.925168 2.58291 1.01434C2.36928 1.10352 2.17532 1.23393 2.01213 1.39811C1.84894 1.5623 1.71971 1.75705 1.63183 1.97122C1.54396 2.18539 1.49917 2.41478 1.50001 2.64627V14.2327C1.49917 14.4642 1.54396 14.6936 1.63183 14.9078C1.71971 15.1219 1.84894 15.3167 2.01213 15.4809C2.17532 15.6451 2.36928 15.7755 2.58291 15.8646C2.79655 15.9538 3.02566 16 3.25715 16.0006H12.9772L12.5229 14.4148L13.62 15.4327L14.6572 16.3927L16.5 18.0213V2.64627C16.5009 2.41478 16.4561 2.18539 16.3682 1.97122C16.2803 1.75705 16.1511 1.5623 15.9879 1.39811C15.8247 1.23393 15.6307 1.10352 15.4171 1.01434C15.2035 0.925168 14.9744 0.87898 14.7429 0.878418V0.878418ZM11.4343 12.0748C11.4343 12.0748 11.1257 11.7063 10.8686 11.3784C11.4903 11.2331 12.0407 10.8726 12.4222 10.3606C12.1137 10.5658 11.7831 10.7355 11.4364 10.8663C11.0377 11.0365 10.6207 11.1602 10.1936 11.2348C9.45948 11.37 8.70662 11.3671 7.97358 11.2263C7.54323 11.1421 7.12147 11.0187 6.71358 10.8577C6.36915 10.7242 6.04011 10.5539 5.73215 10.3498C6.09896 10.8515 6.62954 11.2093 7.23215 11.3613C6.97501 11.687 6.65787 12.0727 6.65787 12.0727C6.1487 12.0865 5.64396 11.9748 5.18808 11.7476C4.73221 11.5204 4.33917 11.1847 4.04358 10.7698C4.07074 9.03234 4.49204 7.32367 5.27573 5.7727C5.96662 5.23013 6.80902 4.91564 7.68644 4.8727L7.77215 4.97556C6.94736 5.17968 6.17767 5.56306 5.51787 6.09842C5.51787 6.09842 5.70644 5.99556 6.02358 5.84985C6.64038 5.56838 7.29777 5.38614 7.97144 5.30985C8.01942 5.29991 8.06818 5.29418 8.11715 5.2927C8.69154 5.21787 9.27283 5.21211 9.84858 5.27556C10.754 5.37897 11.6305 5.65851 12.4286 6.09842C11.8022 5.58856 11.0748 5.21752 10.2943 5.00985L10.4143 4.8727C11.2917 4.91564 12.1341 5.23013 12.825 5.7727C13.6087 7.32367 14.03 9.03234 14.0572 10.7698C13.7594 11.1846 13.3648 11.5203 12.9076 11.7477C12.4505 11.9752 11.9447 12.0875 11.4343 12.0748Z"
					fill="CurrentColor"
				/>
			</g>
		</svg>
	);
};

export default DiscordSvg;
