import { QualificationsQueryType, StatusQueryType } from '../constants';

let pollTryCount = 0;
const startWithPendingQualification = false;
const startWithClaimedQualification = false;
const patron = Math.random() < 0.25;
const member = Math.random() < 0.2;
const community = Math.random() < 0.6;
const onlyCommunity = !patron && !member && community;

export const mockFetchAllowance = () => {
	return new Promise<QualificationsQueryType>((resolve, reject) => {
		const success = Math.random() < 0.95;
		const qualifications = [
			...(patron
				? [
						{
							slot: 'Patron NFT:100',
							flag: startWithPendingQualification ? 'pending' : 'failed',
							amount: '1000',
						},
						{
							slot: 'Patron NFT:110',
							flag: startWithPendingQualification ? 'pending' : 'failed',
							index: 110,
							amount: '1000',
							quantity: '1',
							claimId: '',
							txLink: '',
						},
						{
							slot: 'Patron NFT:120',
							flag: startWithPendingQualification ? 'pending' : 'failed',
							index: 120,
							amount: '1000',
							quantity: '1',
							claimId: '',
							txLink: '',
						},
						{
							slot: 'Patron NFT:130',
							flag: startWithClaimedQualification ? 'claimed' : 'yes',
							index: 130,
							amount: '1000',
							quantity: '1',
							claimId: '4fDRRkYm4QB79R4d',
							txLink:
								'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
						},
				  ]
				: []),
			...(member
				? [
						{
							slot: 'Genesis Membership NFT:2',
							flag: startWithPendingQualification ? 'pending' : 'failed',
							index: 143,
							amount: '100',
							quantity: '1',
							claimId: '',
							txLink: '',
						},
						{
							slot: 'Genesis Membership NFT:4',
							flag: startWithClaimedQualification ? 'claimed' : 'yes',
							index: 145,
							amount: '100',
							quantity: '1',
							claimId: '4fDRRkYm4QB79R4d',
							txLink:
								'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
						},
				  ]
				: []),
			...(community
				? [
						{
							slot: 'Community Member:DIS#USER#539068537375525329',
							flag: startWithPendingQualification ? 'pending' : 'failed',
							index: 29318,
							amount: '90',
							claimId: '',
							txLink: '',
						},
				  ]
				: []),
		];
		const response = {
			status: 200,
			userId: 'DIS#USER#539068537375525329',
			qualifications,
			indexes: [],
		};
		console.info('fetchAllowance started');
		setTimeout(() => {
			if (success) resolve(response);
			else reject('This is a mock API error.');
			console.info(
				`fetchAllowance completed with status: '${
					success ? 'success' : 'error'
				}'${success ? ` and response: ${JSON.stringify(response)}` : ''}`
			);
		}, 1000);
	});
};

export const mockFetchClaimStatus = () => {
	return new Promise<QualificationsQueryType>((resolve, reject) => {
		console.info('fetchClaimStatus started');
		setTimeout(() => {
			const success = Math.random() < 0.95;
			if (success) {
				const qualifications = [
					...(patron
						? [
								{
									slot: 'Patron NFT:100',
									flag: pollTryCount === 1 ? 'claimed' : 'pending',
									amount: '1000',
									claimId: pollTryCount === 1 ? 'L3DRRkYm4QB79K9H' : '',
									txLink:
										pollTryCount === 1
											? 'https://mumbai.polygonscan.com/tx/0xdec17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c33d7'
											: '',
								},
								{
									slot: 'Patron NFT:110',
									flag: pollTryCount === 1 ? 'claimed' : 'pending',
									index: 110,
									amount: '1000',
									quantity: '1',
									claimId: pollTryCount === 1 ? 'L3DRRkYm4QB79K9H' : '',
									txLink:
										pollTryCount === 1
											? 'https://mumbai.polygonscan.com/tx/0xdec17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c33d7'
											: '',
								},
								{
									slot: 'Patron NFT:120',
									flag: pollTryCount === 1 ? 'claimed' : 'pending',
									index: 120,
									amount: '1000',
									quantity: '1',
									claimId: pollTryCount === 1 ? 'L3DRRkYm4QB79K9H' : '',
									txLink:
										'https://mumbai.polygonscan.com/tx/0xdec17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c33d7',
								},
								{
									slot: 'Patron NFT:130',
									flag: 'claimed',
									index: 130,
									amount: '1000',
									quantity: '1',
									claimId: '4fDRRkYm4QB79R4d',
									txLink:
										'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
								},
						  ]
						: []),
					...(member
						? [
								{
									slot: 'Genesis Membership NFT:2',
									flag: pollTryCount === 1 ? 'claimed' : 'pending',
									index: 143,
									amount: '100',
									quantity: '1',
									claimId: pollTryCount === 1 ? 'L3DRRkYm4QB79K9H' : '',
									txLink:
										pollTryCount === 1
											? 'https://mumbai.polygonscan.com/tx/0xdec17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c33d7'
											: '',
								},
								{
									slot: 'Genesis Membership NFT:4',
									flag: 'claimed',
									index: 145,
									amount: '100',
									quantity: '1',
									claimId: '4fDRRkYm4QB79R4d',
									txLink:
										'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
								},
						  ]
						: []),
					...(community
						? [
								{
									slot: 'Community Member:DIS#USER#539068537375525329',
									flag: pollTryCount === 1 ? 'claimed' : 'pending',
									index: 29318,
									amount: '90',
									claimId: pollTryCount === 1 ? 'L3DRRkYm4QB79K9H' : '',
									txLink:
										pollTryCount === 1
											? 'https://mumbai.polygonscan.com/tx/0xdec17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c33d7'
											: '',
								},
						  ]
						: []),
				];
				const response = {
					status: 200,
					userId: 'DIS#USER#539068537375525329',
					qualifications,
					indexes: [],
				};
				pollTryCount++;
				resolve(response);
			} else {
				reject('This is a mock API error.');
			}
			console.info(
				`fetchClaimStatus completed with status: '${
					success ? 'success' : 'error'
				}'`
			);
		}, 500);
	});
};

export const mockFetchClaim = () => {
	return new Promise<QualificationsQueryType>((resolve, reject) => {
		console.info('fetchClaim started');
		setTimeout(() => {
			const success = Math.random() < 0.95;
			if (success) {
				const qualifications = [
					...(patron
						? [
								{
									slot: 'Patron NFT:100',
									flag: 'pending',
									amount: '1000',
								},
								{
									slot: 'Patron NFT:110',
									flag: 'pending',
									index: 110,
									amount: '1000',
									quantity: '1',
									claimId: '',
									txLink: '',
								},
								{
									slot: 'Patron NFT:120',
									flag: 'pending',
									index: 120,
									amount: '1000',
									quantity: '1',
									claimId: '',
									txLink: '',
								},
								{
									slot: 'Patron NFT:130',
									flag: 'claimed',
									index: 130,
									amount: '1000',
									quantity: '1',
									claimId: '4fDRRkYm4QB79R4d',
									txLink:
										'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
								},
						  ]
						: []),
					...(member
						? [
								{
									slot: 'Genesis Membership NFT:2',
									flag: 'pending',
									index: 143,
									amount: '100',
									quantity: '1',
									claimId: '',
									txLink: '',
								},
								{
									slot: 'Genesis Membership NFT:4',
									flag: 'claimed',
									index: 145,
									amount: '100',
									quantity: '1',
									claimId: '4fDRRkYm4QB79R4d',
									txLink:
										'https://mumbai.polygonscan.com/tx/0xfer17a71fb20bf89167b8916396eb2d701f77b683c885c9353bc21007a3c35gf',
								},
						  ]
						: []),
					...(community
						? [
								{
									slot: 'Community Member:DIS#USER#539068537375525329',
									flag: 'pending',
									index: 29318,
									amount: '90',
									claimId: '',
									txLink: '',
								},
						  ]
						: []),
				];
				const response = {
					status: 200,
					userId: 'DIS#USER#539068537375525329',
					qualifications,
					indexes: [],
				};
				resolve(response);
			} else {
				reject('This is a mock API error.');
			}
			console.info(
				`fetchClaim completed with status: '${success ? 'success' : 'error'}'`
			);
		}, 1000);
	});
};

export const mockFetchWalletStatus = () => {
	return new Promise<StatusQueryType>((resolve, reject) => {
		console.info('fetchWalletStatus started');
		setTimeout(() => {
			const success = Math.random() < 0.95;
			if (success) {
				const isWalletCreated = Math.random() < 0.5;
				const walletCreated =
					isWalletCreated && onlyCommunity
						? '0xAef38fBFBF932D1AeF3B808Bc8fBd8Cd8E1f8BC5'
						: '';
				const response = { walletCreated };
				resolve(response);
			} else {
				reject('This is a mock API error.');
			}
			console.info(
				`fetchWalletStatus completed with status: '${
					success ? 'success' : 'error'
				}'`
			);
		}, 500);
	});
};
