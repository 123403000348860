import styles from '../SocialButton.module.scss';

const MediumSvg = () => {
	return (
		<svg
			width="23"
			height="14"
			viewBox="0 0 23 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={styles.svgHoverFill}
				d="M6.48656 13.6968C10.0692 13.6968 12.9733 10.7865 12.9733 7.19667C12.9733 3.60683 10.0689 0.696777 6.48656 0.696777C2.90419 0.696777 0 3.60596 0 7.19667C0 10.7874 2.90397 13.6968 6.48656 13.6968Z"
				fill="CurrentColor"
			/>
			<path
				className={styles.svgHoverFill}
				d="M16.846 13.3153C18.6372 13.3153 20.0894 10.5761 20.0894 7.19667C20.0894 3.81815 18.6372 1.07799 16.846 1.07799C15.0548 1.07799 13.6026 3.81815 13.6026 7.19667C13.6026 10.5752 15.0548 13.3153 16.846 13.3153Z"
				fill="CurrentColor"
			/>
			<path
				className={styles.svgHoverFill}
				d="M21.8593 12.6788C22.4892 12.6788 23 10.2244 23 7.19667C23 4.16911 22.4896 1.71458 21.8595 1.71458C21.2293 1.71458 20.7185 4.16977 20.7185 7.19667C20.7185 10.2236 21.2293 12.6788 21.8593 12.6788Z"
				fill="CurrentColor"
			/>
		</svg>
	);
};

export default MediumSvg;
