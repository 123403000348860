import cx from 'classnames';
import { DiscordSvg } from './DiscordSvg';
import { LinktreeSvg } from './LinktreeSvg';
import { MediumSvg } from './MediumSvg';
import { TwitterSvg } from './TwitterSvg';
import styles from './SocialButton.module.scss';
import { socialLinks } from '../../constants';

interface Props {
	type: 'discord' | 'medium' | 'twitter' | 'linktree';
	width?: number | null;
	className?: string;
	disabled?: boolean;
}

const SocialButton = ({ type, width, className, disabled }: Props) => {
	return (
		<a
			className={cx(
				'socialLinkButton',
				disabled && styles.buttonDisabled,
				!disabled && styles.buttonHover,
				className
			)}
			style={{ ...(width && { width }) }}
			href={socialLinks[type]}
			target="_blank"
			rel="noreferrer"
		>
			{type === 'discord' && <DiscordSvg />}
			{type === 'medium' && <MediumSvg />}
			{type === 'twitter' && <TwitterSvg />}
			{type === 'linktree' && <LinktreeSvg />}
		</a>
	);
};

export default SocialButton;
