import styles from '../SocialButton.module.scss';

const DiscordSvg = () => {
	return (
		<svg
			width="18"
			height="15"
			viewBox="0 0 18 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={styles.svgHoverFill}
				d="M17.5714 2.33161C16.9286 2.59947 16.2857 2.81376 15.5357 2.86733C16.2857 2.43876 16.8214 1.74233 17.0893 0.938756C16.3929 1.36733 15.6429 1.63518 14.8393 1.7959C14.1964 1.09947 13.2857 0.670898 12.3214 0.670898C10.3929 0.670898 8.78571 2.22447 8.78571 4.20661C8.78571 4.47447 8.83929 4.74233 8.89286 5.01018C5.94643 4.84947 3.375 3.45661 1.60714 1.31376C1.28571 1.84947 1.125 2.43876 1.125 3.08161C1.125 4.31376 1.76786 5.38518 2.67857 6.02804C2.08929 6.02804 1.55357 5.86733 1.07143 5.59947V5.65304C1.07143 7.36733 2.30357 8.76018 3.91071 9.08161C3.58929 9.13518 3.32143 9.18875 3 9.18875C2.78571 9.18875 2.57143 9.18875 2.35714 9.13518C2.78571 10.528 4.125 11.5459 5.625 11.5995C4.44643 12.5638 2.89286 13.0995 1.23214 13.0995C0.964286 13.0995 0.642857 13.0995 0.375 13.0459C1.98214 14.0102 3.85714 14.5995 5.83929 14.5995C12.3214 14.5995 15.8571 9.24233 15.8571 4.58161C15.8571 4.4209 15.8571 4.26018 15.8571 4.15304C16.5 3.61733 17.0893 3.02804 17.5714 2.33161Z"
				fill="CurrentColor"
			/>
		</svg>
	);
};

export default DiscordSvg;
