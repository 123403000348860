import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Logo } from '../';
import styles from './Footer.module.scss';

interface Props {
	absolute?: boolean;
}

const Footer = ({ absolute }: Props) => {
	return (
		<div className={cx(styles.container, absolute && styles.containerAbsolute)}>
			<div className={styles.content}>
				<Logo />
				<div className={styles.rightSection}>
					<Link className={styles.link} to="/faq">
						FAQ
					</Link>
					<a
						className={styles.link}
						href="https://www.collab.land/privacy-policy "
					>
						Privacy Policy
					</a>
					<a
						className={styles.link}
						href="https://www.collab.land/terms-of-service "
					>
						Terms of Service
					</a>
					{/* <Link className={styles.link} to="/token-story">
						Token Story
					</Link> */}
					<a className={styles.link} href="https://www.collab.land">
						©2023 Collab.Land
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
