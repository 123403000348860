import { useEffect } from 'react';
import { useLocation } from 'react-router';
import slugify from 'slugify';
import { Footer } from '../../components';
import { Layout } from '../../components/Layout';
import { faqAssistance } from '../../images';

import styles from './FaqView.module.scss';

const LinkIcon = () => (
	<svg height="16" version="1.1" viewBox="0 0 16 16" width="16">
		<path
			fillRule="evenodd"
			d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
		></path>
	</svg>
);

const slugRE = /[*+~.()'"!:@]/g;

const Title = ({ content }: { content: string }) => {
	const slug = slugify(content, { lower: true, remove: slugRE });
	return (
		<h3 id={slug}>
			<a className={styles.faqFragment} href={`#${slug}`}>
				<LinkIcon />
			</a>
			{content}
		</h3>
	);
};

const FaqView = () => {
	const location = useLocation();

	useEffect(() => {
		const elementToScroll = document.getElementById(
			location.hash?.replace('#', '')
		);

		if (!elementToScroll) {
			window.scrollTo(0, 0);
			return;
		}
		window.scrollTo({
			top: elementToScroll.offsetTop,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout styles={styles.wrapper}>
			<div className={styles.container}>
				<h2>Token Claim FAQ</h2>
				<h6 className="margin-top-1">Overview</h6>
				<p>
					There are three personas that are eligible for the Collab.Land token
					claim.
					<ol>
						<li>Community Members</li>
						<li>Collab.Land Membership NFT Holders</li>
						<li>Collab.Land Patron NFT Holders</li>
					</ol>
					Members claiming tokens can sort themselves by which persona they are
					claiming tokens as.
				</p>
				<Title content="I’m a verified Community Member:" />
				<p>
					Members who have verified their wallet with a community managed by the
					Collab.Land bot before the snapshot (Feb 14, 2023 at 12pm PST) are
					eligible to claim 10 tokens.
				</p>
				<ol>
					<li>
						<h6>Discord</h6> Discord Community Members must have verified at
						least one wallet with the Collab.Land bot before the snapshot date
						(Feb 14, 2023 at 12pm PST) to be eligible to claim $COLLAB tokens.
						Community Members are eligible to claim 10 tokens.
					</li>
					<li>
						<h6>Telegram</h6> Telegram Community Members must have verified at
						least one wallet with the Collab.Land bot before the snapshot date
						(Feb 14, 2023 at 12pm PST) to be eligible to claim $COLLAB tokens.
						Community Members are eligible to claim 10 tokens.
					</li>
				</ol>
				<Title content="I’m a verified Membership NFT holder:" />

				<p>
					Members who hold the Membership NFT with token ID 0 - 28313 (snapshot
					taken Feb 14, 2023 at 12pm PST) are eligible to claim 1,412 $COLLAB
					tokens per Membership NFT.
				</p>

				<Title content="Troubleshooting Membership NFT claim" />

				<p>
					If you hold a qualifying Membership NFT, if it is still eligible to be
					claimed:{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						className={styles.faqLink}
						href="https://wagmi.collab.land/eligibility"
					>
						https://wagmi.collab.land/eligibility
					</a>{' '}
					and the claim site shows that you are not eligible for Membership NFT
					tokens, follow these troubleshooting steps.
				</p>
				<ol>
					<li>
						Re-verify the wallet that holds the Membership NFT with the
						Collab.Land bot
						<ol type="a">
							<li>
								Join the Collab.Land Discord{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className={styles.faqLink}
									href="https://discord.com/invite/collabland"
								>
									https://discord.com/invite/collabland
								</a>
								. Please make sure you have a profile picutre in your Discord
								profile else you will not be able to enter the Discord server.
							</li>
							<li>Click “Let’s go!” button in the #collabland-join channel</li>
							<li>
								Click “Connect Wallet” button in the #collabland-join channel
							</li>
							<li>Verify the wallet address that holds your Membership NFT</li>
							<li>
								Claim tokens from Membership NFT on claim site{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className={styles.faqLink}
									href="https://wagmi.collab.land"
								>
									https://wagmi.collab.land
								</a>
							</li>
						</ol>
					</li>
				</ol>
				<Title content="I’m a Patron NFT holder:" />
				<p>
					Members who hold the Patron NFT with token ID 0 - 141 (snapshot taken
					Feb 14, 2023 at 12pm PST) are eligible to claim 281,690 $COLLAB tokens
					per Patron NFT.
				</p>

				<Title content="Troubleshooting Patron NFT claim" />

				<p>
					If you hold a qualifying Patron NFT, if it is still eligible to be
					claimed:{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						className={styles.faqLink}
						href="https://wagmi.collab.land/eligibility"
					>
						https://wagmi.collab.land/eligibility
					</a>{' '}
					and the claim site shows that you are not eligible for Patron NFT
					tokens, follow these troubleshooting steps.
				</p>
				<ol>
					<li>
						Re-verify the wallet that holds the Membership NFT with the
						Collab.Land bot
						<ol type="a">
							<li>
								Join the Collab.Land Discord{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className={styles.faqLink}
									href="https://discord.com/invite/collabland"
								>
									https://discord.com/invite/collabland
								</a>
								. Please make sure you have a profile picutre in your Discord
								profile else you will not be able to enter the Discord server.
							</li>
							<li>Click “Let’s go!” button in the #collabland-join channel</li>
							<li>
								Click “Connect Wallet” button in the #collabland-join channel
							</li>
							<li>Verify the wallet address that holds your Patron NFT</li>
							<li>
								Claim tokens from Patron NFT on claim site{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className={styles.faqLink}
									href="https://wagmi.collab.land"
								>
									https://wagmi.collab.land
								</a>
							</li>
						</ol>
					</li>
				</ol>

				<Title content="I hold both Patron & Membership NFTs:" />

				<p>
					Patron NFTs with token ID 0 - 141 are eligible to claim 281,690
					$COLLAB tokens per Patron NFT and Membership NFTs with token ID 0 -
					28313 are eligible to claim 1,412 $COLLAB tokens per Membership NFT.
					Snapshots for both NFT types were taken Feb 14, 2023 at 12pm PST.
					Members may hold Patron & Membership NFTs in the same wallet or in
					separate wallets.
				</p>

				<Title content="Patron & Membership NFTs in the same wallet" />

				<p>
					If the claim site shows that you don’t qualify for the tokens for both
					of these NFTs, re-verify the wallet that holds the NFTs with the
					Collab.Land bot
				</p>
				<h6>Steps to verify the wallet that holds both NFTs types:</h6>
				<ol>
					<li>
						Join the Collab.Land Discord{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://discord.com/invite/collabland"
						>
							https://discord.com/invite/collabland
						</a>{' '}
						.Please make sure you have a profile picutre in your Discord profile
						else you will not be able to enter the Discord server.
					</li>
					<li>Click “Let’s go!” button in the #collabland-join channel</li>
					<li>Click “Connect Wallet” button in the #collabland-join channel</li>
					<li>Verify the wallet address that holds your Patron NFT</li>
					<li>
						Claim tokens from NFTs on claim site{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://wagmi.collab.land"
						>
							https://wagmi.collab.land
						</a>
					</li>
				</ol>

				<Title content="Patron & Membership NFTs in different wallets" />

				<p>
					Currently, the Collab.Land bot allows only 1 EVM wallet connection per
					community at a time. But Members can go through the claim flow
					multiple times if they have qualified claims to unclaimed tokens.
				</p>
				<br />
				<p>
					This means that you will need to separate your connections, either to
					different communities or go through the token claim flow multiple
					times after switching which wallet is connected with the Collab.Land
					bot.
				</p>
				<br />
				<p>
					First claim the tokens associated with one NFT type, then change your
					Collab.Land-verified wallet to the one that holds the other NFT type
					and finally claim those other tokens.
				</p>

				<h6>Steps to claiming multiple NFTs in different wallets:</h6>
				<ol>
					<li>
						Join the Collab.Land Discord{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://discord.com/invite/collabland"
						>
							https://discord.com/invite/collabland
						</a>
						. Please make sure you have a profile picutre in your Discord
						profile else you will not be able to enter the Discord server.
					</li>
					<li>Click “Let’s go!” button in the #collabland-join channel</li>
					<li>Click “Connect Wallet” button in the #collabland-join channel</li>
					<li>Verify the wallet address that holds your Patron NFT</li>
					<li>
						Claim tokens from Patron NFT on claim site{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://wagmi.collab.land"
						>
							https://wagmi.collab.land
						</a>
					</li>
					<li>(Switch Collab.Land-verified wallets + claim again)</li>
					<li>Click “Let’s go!” button in the #collabland-join channel</li>
					<li>Click “Connect Wallet” button in the #collabland-join channel</li>
					<li>Verify the wallet address that holds your Membership NFT</li>
					<li>
						Claim tokens from Membership NFT on claim site{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://wagmi.collab.land"
						>
							https://wagmi.collab.land
						</a>
					</li>
				</ol>

				<Title content="Troubleshooting:" />
				<ul>
					<li>
						<strong>
							Ensure you have logged in using the correct Discord account, we
							cannot verify any token claims without having the correct Discord
							account connected.
						</strong>
					</li>
					<li>
						Members can go through the claim flow multiple times if they have
						qualified claims to unclaimed tokens.
					</li>
					<li>
						You can only claim once for each Patron and Membership NFT token ID.{' '}
					</li>
					<li>
						To check the eligibility of an NFT token ID, please go to:{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://wagmi.collab.land/eligibility"
						>
							https://wagmi.collab.land/eligibility
						</a>
					</li>
					<li>
						Tokens can be claimed to any EVM wallet address. It does not have to
						be a wallet address verified with Collab.Land. You will just need to
						input the wallet address, no need to connect.
					</li>
					<li>Claim is gas-free for members and is sponsored by Collab.Land</li>
					<li>
						If you get a “Duplicate Wallet” error, please read:{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.faqLink}
							href="https://help.collab.land/wallets/duplicate-wallet"
						>
							https://help.collab.land/wallets/duplicate-wallet
						</a>
					</li>
				</ul>

				<Title content="I don’t see $COLLAB tokens in my wallet after I claimed" />

				<p>
					The $COLLAB token is an ERC20 token on the Optimism network. You can
					add the the Optimism network directly from MetaMaskGo by clicking “Add
					Network” or go to:{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						className={styles.faqLink}
						href="https://chainlist.org/chain/10"
					>
						https://chainlist.org/chain/10
					</a>{' '}
					and click “Add to MetaMask”.
				</p>

				<Title content="How to add the $COLLAB token in MetaMask" />

				<p>
					Note: You must already have added the Optimism network to your wallet
					to start this process.
				</p>
				<ol>
					<li>Switch to the Optimism network in your wallet</li>
					<li>Select “Assets” tab in metamask</li>
					<li>
						Press the "Import tokens" button at the bottom of the asset list
						screen.
						<ul>
							<li>
								Enter “Token contract address”: “
								<strong>0x8B21e9b7dAF2c4325bf3D18c1BeB79A347fE902A</strong>”
							</li>
							<li>“Token Symbol”: “COLLAB”</li>
							<li>“Token Decimal”: 18</li>
						</ul>
					</li>
					<li>
						Your Collab tokens will now be visible in your MetaMask wallet.
					</li>
				</ol>
				<img
					className={styles.faqGif}
					src={faqAssistance}
					alt="metamask"
					width={400}
					height={675}
				/>
			</div>
			<Footer />
		</Layout>
	);
};

export default FaqView;
