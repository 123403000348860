import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import { Logo } from '../Logo';
import { NavButton } from '../NavButton';
import { UserInfo } from '../UserInfo';
import styles from './Nav.module.scss';

const Nav = () => {
	const location = useLocation();

	const logout = useStore(store => store.logout);
	// const claimStarted = useStore(state => state.claimStarted);

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				{['/claim', '/thankyou'].includes(location.pathname) && (
					<div className={styles.leftSideContainer}>
						<UserInfo />
					</div>
				)}
				<a className={styles.logoLink} href="https://www.collab.land">
					<Logo />
				</a>
				{/* <div className={styles.rightSideContainer}>
					{location.pathname === '/' && !claimStarted && (
						<NavButton>Notify Me</NavButton>
					)}
				</div> */}
				{location.pathname === '/claim' && (
					<div className={styles.rightSideContainer}>
						<NavButton className="gtm-logout" onClick={logout}>
							Logout
						</NavButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default Nav;
