import { create } from 'zustand';
import { QualificationArrayType } from '../constants';

interface AppState {
	loading: boolean;
	setLoading: (value: boolean) => void;
	error: string;
	setError: (value: string) => void;
	verifying: boolean;
	setVerifying: (value: boolean) => void;
	claiming: boolean;
	setClaiming: (value: boolean) => void;
	claimStarted: boolean;
	setClaimStarted: (value: boolean) => void;
	eligibility: QualificationArrayType;
	setEligibility: (value: QualificationArrayType) => void;
	userId: string;
	setUserId: (value: string) => void;
	wallet: string;
	setWallet: (value: string) => void;
	walletCreated: string;
	setWalletCreated: (value: string) => void;
	videoWatched: boolean;
	setVideoWatched: (value: boolean) => void;
	videoClosed: boolean;
	setVideoClosed: (value: boolean) => void;
	isAuthenticated: boolean | null;
	setIsAuthenticated: (value: boolean) => void;
	logout: () => void;
}

const useStore = create<AppState>(set => ({
	loading: true,
	setLoading: loading => set({ loading }),
	error: '',
	setError: error => set({ error }),
	verifying: false,
	setVerifying: verifying => set({ verifying }),
	claiming: false,
	setClaiming: claiming => set({ claiming }),
	claimStarted: true, // Math.random() < 0.6,
	setClaimStarted: claimStarted => set({ claimStarted }),
	eligibility: [
		{
			index: -1,
			amount: '',
			slot: '',
			flag: '',
		},
	],
	setEligibility: eligibility => set({ eligibility }),
	userId: '',
	setUserId: userId => set({ userId }),
	wallet: '',
	setWallet: wallet => set({ wallet }),
	walletCreated: '',
	setWalletCreated: walletCreated => set({ walletCreated }),
	videoWatched: false,
	setVideoWatched: videoWatched => set({ videoWatched }),
	videoClosed: false,
	setVideoClosed: videoClosed => set({ videoClosed }),
	isAuthenticated: null,
	setIsAuthenticated: isAuthenticated => set({ isAuthenticated }),
	logout: () =>
		set(state => {
			localStorage.removeItem('aeToken');
			return { ...state, isAuthenticated: false, userId: '', error: '' };
		}),
}));

export default useStore;
