import {
	eligibilityList,
	eligibilityTypes,
	FinalContentType,
	QualificationArrayType,
} from '../constants';

export * from './url';

const body = document.body;
const html = document.documentElement;

export function getPageHeight() {
	return Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight
	);
}

export const getTokenPrice = (price: number | string, closure = true) => {
	// if (Number(price) === 0) return 'FREE';
	const formatedAmount = Number(price).toLocaleString('en-US');
	const finalAmount = closure ? `${formatedAmount} Tokens` : formatedAmount;
	return finalAmount;
};

export function mapValue(
	value: number,
	threshold1: number,
	threshold2: number,
	min: number,
	max: number
) {
	if (value <= threshold1) {
		return min;
	} else if (value <= threshold2) {
		return (
			((value - threshold1) / (threshold2 - threshold1)) * (max - min) + min
		);
	} else {
		return max;
	}
}

export function getWalletPlaceholder() {
	// return '0xAef38fBFBF932D1AeF3B808Bc8fBd8Cd8E1f8BC5';
	return 'Enter valid wallet/ENS address';
}

const getCalculations = (items: QualificationArrayType = [], title = '') => {
	let label = '';
	let quantitySum = 0;
	let sum = 0;
	(items ?? []).forEach(({ slot, amount, quantity }) => {
		const slotSplit = (slot ?? '').split(':');
		if (label === '') label = `${title ?? ''} #${slotSplit?.[1] ?? ''}`;
		else label = `${label} #${slotSplit?.[1] ?? ''}`;
		quantitySum += parseInt(`${quantity ?? 1}`);
		sum += parseInt(`${amount ?? 0}`);
	});
	return { label, quantity: quantitySum, amount: sum };
};

export const getItems = (allItems: QualificationArrayType) => () => {
	return eligibilityList.map(({ title, type }, index) => {
		const isCommunity = type === eligibilityTypes['community'];
		const items = allItems?.filter(({ slot }) => (slot ?? '').includes(type));
		const { label: l, quantity, amount } = getCalculations(items, title);
		const key = title ?? index;
		const label = isCommunity || !l ? title : l;
		return { key, label, quantity, amount };
	});
};

export const getItemsGroupedByTxLink =
	(links: string[], allItems: QualificationArrayType) => () => {
		const res: FinalContentType = [];
		eligibilityList.forEach(({ title, type }, index) => {
			links.forEach((txLinkUnique, linkIndex) => {
				const isCommunity = type === eligibilityTypes['community'];
				const items = allItems?.filter(({ slot, txLink = '' }) => {
					return (slot ?? '').includes(type) && txLink === txLinkUnique;
				});
				const { label: l, quantity, amount } = getCalculations(items, title);
				const key = l ?? index + linkIndex;
				const label = isCommunity || !l ? title : l;
				const txLink = amount > 0 ? txLinkUnique : '';
				if (amount > 0) res.push({ key, label, quantity, amount, txLink });
			});
			const itemsInGroup = res.filter(({ label }) => label?.includes(title));
			if (itemsInGroup.length === 0) res.push({ key: title, label: title });
		});
		return res;
	};

export const positiveIntegerRegExp = /^[0-9]+$/;

export function getUserIdInfo(userId: string) {
	const userInfo = userId.split('#');
	const label =
		userInfo?.[0] === 'DIS'
			? 'Discord'
			: userInfo?.[0] === 'TEL'
			? 'Telegram'
			: 'Unknown';
	return {
		userIdLabel: userInfo?.[2] ?? '',
		source: `Logged in with ${label}`,
	};
}
