import { Footer, SocialButton } from '../../components';
import {
	flyingMascotMobileSrc,
	flyingMascotSrc,
	globeBlueSrc,
	globePurpleSrc,
	globeYellowSrc,
} from '../../images';

import IntroView from '../IntroView';
import { Layout } from '../../components/Layout';
import cx from 'classnames';
import { motion } from 'framer-motion';
import styles from './TeaserView.module.scss';
import useScreenSize from '../../hooks/useScreenSize';
import { useScrollToTop } from '../../hooks';

const bounceAnimation = {
	y: [0, -10, 0],
};

const bounceAnimation2 = {
	x: [0, 10, 0],
};

const bounceAnimation3 = {
	y: [0, 10, 0],
};

const TeaserView = () => {
	useScrollToTop();
	const screenSize = useScreenSize();

	const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);

	return (
		<>
			<div className={styles.endClaimBanner}>Claim ended May 23, 2023</div>
			<Layout styles={styles.wrapper}>
				<div className={styles.page}>
					<div className={styles.content}>
						<h1 className={cx(styles.header, 'hide-on-mobile')}>
							Community is our superpower
						</h1>
						<span className={cx(styles.headerMobile, 'hide-on-desktop')}>
							<h1 className={styles.headerColor}>
								Community is our superpower
							</h1>
							<div className={styles.headerInnerMobile}>
								{/* <div>
									<h1 className={styles.headerColor}>Well we</h1>
									<h1 className={styles.headerColor}>love you!</h1>
								</div> */}
								{/* <img className={styles.heart} src={heartBubbleSrc} alt="" /> */}
							</div>
						</span>
						<p style={{ color: '#fff', marginTop: '48px' }}>
							By uniting groups around common purposes we are able to go far,
							together.
						</p>
						<p style={{ color: '#fff' }}>
							Now we’re ready to take this to the next level.
						</p>

						<div className={styles.socialContainer}>
							<SocialButton type="discord" />
							{/* <SocialButton type="medium" /> */}
							<SocialButton type="twitter" />
							<SocialButton type="linktree" />
						</div>
					</div>
					{!isMobileScreen && (
						<>
							<motion.div
								animate={bounceAnimation}
								transition={{ repeat: Infinity, duration: 5 }}
								className={cx(styles.globePurpleContainer, 'hide-on-mobile')}
							>
								<img
									className={styles.globePurple}
									src={globePurpleSrc}
									alt=""
								/>
							</motion.div>
							<motion.div
								animate={bounceAnimation2}
								transition={{ repeat: Infinity, duration: 9 }}
								className={cx(styles.globeYellowContainer, 'hide-on-mobile')}
							>
								<img
									className={styles.globeYellow}
									src={globeYellowSrc}
									alt=""
								/>
							</motion.div>
							<motion.div
								animate={bounceAnimation3}
								transition={{ repeat: Infinity, duration: 7 }}
								className={cx(styles.globeBlueContainer, 'hide-on-mobile')}
							>
								<img className={styles.globeBlue} src={globeBlueSrc} alt="" />
							</motion.div>
						</>
					)}
					{isMobileScreen ? (
						<img
							className={styles.mascot}
							width={600}
							height={1000}
							src={flyingMascotMobileSrc}
							alt=""
						/>
					) : (
						<img
							className={styles.mascot}
							width={900}
							height={1600}
							src={flyingMascotSrc}
							alt=""
						/>
					)}
				</div>
			</Layout>
			<IntroView />
			<Footer />
		</>
	);
};

export default TeaserView;
