export * from './eligibilityTypes';
export * from './types';

export const socialLinks = {
	discord: 'https://discord.gg/collabland',
	medium: 'https://medium.com/collab-land',
	twitter: 'https://twitter.com/Collab_Land_',
	linktree: 'https://linktr.ee/collab_land_',
};

export const walletRegex = /^(0x[a-fA-F0-9]{40}|(.*).eth)$/;

export const walletError = 'Wallet/ENS address not valid.';
