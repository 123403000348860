import { useEffect, useState } from 'react';
import variables from '../styles/breakpoints.module.scss';

type Screen = 'mobile' | 'tablet' | 'desktop' | '2k' | '4k';

const breakpoints = Object.keys(variables).reduce((obj: any, current) => {
	obj[current] = parseInt(variables[current], 10);
	return obj;
}, {}) as Record<keyof typeof variables, number>;

const useScreenSize = () => {
	const [breakpoint, setBreakpoint] = useState<Screen>('mobile');

	const checkScreenSize = () => {
		const width = window.innerWidth;
		switch (true) {
			case width < breakpoints.small:
				setBreakpoint('mobile');
				break;
			case width >= breakpoints.small && width < breakpoints.medium:
				setBreakpoint('tablet');
				break;
			case width >= breakpoints.medium && width < breakpoints.large:
				setBreakpoint('desktop');
				break;
			case width >= breakpoints.large && width < breakpoints.xlarge:
				setBreakpoint('2k');
				break;
			case width >= breakpoints.xlarge:
				setBreakpoint('4k');
				break;
			default:
				setBreakpoint('desktop');
		}
	};

	useEffect(() => {
		checkScreenSize();

		window.addEventListener('resize', checkScreenSize);

		return () => {
			window.removeEventListener('resize', checkScreenSize);
		};
	}, []);

	return breakpoint;
};

export default useScreenSize;
