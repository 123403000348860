import {
	heartBub,
	lego,
	stars,
	target,
	token,
	token2,
	lockedToken1,
	lockedToken2,
	lockedToken3,
} from '../../images';

import React from 'react';
import styles from './IntroView.module.scss';
import useScreenSize from '../../hooks/useScreenSize';

const IntroView: React.FunctionComponent = () => {
	const screenSize = useScreenSize();

	const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);
	return (
		<>
			<div className={styles.faq}>
				<img
					className={styles.mascot}
					width={60}
					height={60}
					src={target}
					alt=""
				/>
				FAQ
			</div>
			<div className={`${styles.page} ${styles.pageOne}`}>
				<div className={styles.emojiContainer}>
					<img
						className={styles.mascot}
						style={{ marginRight: '60px' }}
						width={500}
						src={stars}
						alt=""
					/>
				</div>
				<div className={styles.textContainer}>
					<div className={styles.pageTitle}>What is the Collab.Land token?</div>
					<p className={styles.pageDesc}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://optimistic.etherscan.io/address/0x8b21e9b7daf2c4325bf3d18c1beb79a347fe902a"
						>
							COLLAB token
						</a>{' '}
						is primarily for governance and use within the Collab.Land
						ecosystem. Decentralization is one of our core values, and we open
						the gates of community stewardship to you. If you choose to join us,
						we will govern together as the Collab.Land DAO. We will vote on
						feature requests, offer bounties, curate the{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://collabland.mirror.xyz/e9mooPGCq1N4O-3Gu6cQs3lUIvWNrttjkAuxrzmwlfI"
						>
							Marketplace
						</a>
						, and more.
					</p>
					<p className={styles.pageDesc}>
						The token claim began on February 23 at 6 pm Mountain Time (MT),
						2023 on{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://wagmi.collab.land"
						>
							wagmi.collab.land
						</a>
						.
					</p>
					<p className={styles.pageDesc}>
						Official COLLAB token contract is{' '}
						{isMobileScreen ? (
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.addrLink}
								href="https://optimistic.etherscan.io/address/0x8b21e9b7daf2c4325bf3d18c1beb79a347fe902a"
							>
								0x8B21...902A
							</a>
						) : (
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.addrLink}
								href="https://optimistic.etherscan.io/address/0x8b21e9b7daf2c4325bf3d18c1beb79a347fe902a"
							>
								0x8B21e9b7dAF2c4325bf3D18c1BeB79A347fE902A
							</a>
						)}{' '}
						on Optimism .
					</p>
					<p className={(styles.pageDesc, styles.disclaimerMessage)}>
						Any contract activity or transactions prior to February 23 at 6 pm
						Mountain Time (MT), 2023, are for verification purposes and system
						reliability.
					</p>
					<div className={styles.pageTitle}>
						Who was eligible for the Collab.Land token?
					</div>
					<p className={styles.pageDesc}>
						The following four groups were eligible for a sponsored claim. A
						sponsored claim means that Collab.Land was not requiring claimants
						to pay gas. This also means that no wallet connection was required.
					</p>
					<p className={styles.pageDesc}>
						1. Verified Community Members in Discord or Telegram
					</p>
					<p className={styles.pageDesc}>
						2. Collab.Land’s{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://collabland.mirror.xyz/Rkg2VBIq1cmt87-hqW5cOqdXMZTuLTXwGE9D2-fDI5Q"
						>
							Top 100
						</a>{' '}
						Discord communities based on membership, longevity, and activity
					</p>
					<p className={styles.pageDesc}>
						3. Collab.Land Patron NFT holders (token numbers 0-141)
					</p>
					<p className={styles.pageDesc}>
						4. Collab.Land Membership NFT holders
					</p>
					<p className={styles.pageDesc}>
						For groups #1 and #2, the snapshot was taken on 02/14/23.
					</p>
					<p>For #3 and #4, NFTs can each claim tokens once.</p>
					<div className={styles.pageTitle}>
						What is the token claim period?
					</div>
					<p className={styles.pageDesc}>
						Tokens can be claimed starting on February 23 at 6 pm MT, 2023 until
						May 23 6 pm MT, 2023.
					</p>
				</div>
			</div>

			<div className={`${styles.page} ${styles.pageTwo}`}>
				<div className={`${styles.textContainer} ${styles.pagePadding}`}>
					<div className={styles.pageTitle}>How do I claim $COLLAB?</div>
					<p className={styles.pageDesc}>
						The COLLAB token claim happened only on{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://wagmi.collab.land"
						>
							wagmi.collab.land
						</a>{' '}
						(this page) starting on February 23 at 6 pm MT, 2023 until May 23 6
						pm MT, 2023.
					</p>
					<p className={styles.pageDesc}>
						1. Start your claim and choose to authorize your Discord or Telegram
						account. If you wish to authorize both, you will go through the
						token claim again. If you are a Collab.Land Membership NFT or Patron
						NFT holder, join the{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://discord.gg/collabland"
						>
							Collab.Land Discord
						</a>{' '}
						and claim your role before continuing with the token claim process.
					</p>
					<p className={styles.pageDesc}>
						2. Follow the steps to learn about $COLLAB and the Collab.Land DAO.
					</p>
					<p className={styles.pageDesc}>
						3. Once your distribution is determined, you will submit the
						Ethereum wallet address you wish to send your tokens. No message
						signature is required.
					</p>
					<p className={styles.pageDesc}>
						4. Collab.Land will send COLLAB tokens to that wallet address.
					</p>
					<p className={styles.pageDesc}>
						NOTE: you <span className={styles.notice}>DO NOT</span> need to sign
						a message with your wallet or pay gas to claim.
					</p>
					<p className={styles.pageDesc}>
						The COLLAB token and upcoming DAO will be launched on{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://www.optimism.io/"
						>
							Optimism
						</a>
						. To claim $COLLAB, recipients can use their desired Ethereum wallet
						address. However, to see their $COLLAB in their Optimism wallets and
						to participate in DAO governance, holders will need to add the
						Optimism network to their wallet.
					</p>
					<p className={styles.pageDesc}>
						See how to use{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://help.optimism.io/hc/en-us/articles/4411903123483-How-do-I-add-the-Optimism-network-to-my-wallet-"
						>
							Optimism on Metamask
						</a>
						.
					</p>
					<div className={styles.pageTitle}>
						What is the Top 100 Communities token claim about?
					</div>
					<p className={styles.pageDesc}>
						This is something the core team is incredibly proud and excited
						about, allowing our Top 100 Discord communities to determine how
						they want to use or distribute 15% of the total $COLLAB supply.
					</p>
					<p className={styles.pageDesc}>
						These communities were chosen based on three factors. First, the
						number of verified wallets connected to a given community. Second,
						the number of days a given community has been using the Collab.Land
						bot. And third, has met the minimum activity threshold determined by
						the core team.
					</p>
					<div className={styles.pageTitle}>
						How do the top 100 Discord communities claim their $COLLAB
						distribution?
					</div>
					<p className={styles.pageDesc}>
						In order to claim tokens, each of the Top 100 communities must go
						through the Community Claim Process. The goal is to propose a unique
						and creative mechanism for how they will use and/or distribute the
						tokens. Top 100 communities can learn more in this{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="https://collabland.mirror.xyz/Rkg2VBIq1cmt87-hqW5cOqdXMZTuLTXwGE9D2-fDI5Q"
						>
							Mirror article
						</a>
						.
					</p>
					<p className={styles.pageDesc}>
						We encourage all Top 100 communities that have been allocated COLLAB
						tokens to begin discussions with the core team via the{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.introLink}
							href="http://discord.gg/collabland"
						>
							Collab.Land Discord
						</a>
						.
					</p>
				</div>
				<div className={styles.emojiContainer}>
					<img
						className={styles.mascot}
						style={{ marginLeft: '60px' }}
						width={500}
						src={heartBub}
						alt=""
					/>
				</div>
			</div>

			<div className={`${styles.page} ${styles.pageThree}`}>
				<div className={styles.pictureTextContainer}>
					<div className={`${styles.textContainer} ${styles.pagePadding}`}>
						<div className={styles.pageTitle}>
							What is the COLLAB token distribution?{' '}
						</div>
						<p className={styles.pageDesc}>
							The COLLAB token distribution is as follows:
						</p>
						<p className={styles.pageDesc}>
							1. DAO Treasury = 50% (500MM) (reserved for future distributions
							and use)
						</p>
						<p className={styles.pageDesc}>
							2. Team/Investors/Partners = 25% (250MM)
						</p>
						<p className={styles.pageDesc}>
							3. Retroactive Distribution = 25% (250MM)
						</p>
						<p className={styles.pageDesc}>
							The DAO Treasury will hold 50% of the total supply of $COLLAB.
							This allocation serves as the long-term community reserves, and we
							anticipate that proposals will be made into the future to allocate
							these tokens towards different community initiatives, including
							grants, airdrops, and other incentive / distribution programs.
						</p>
						<p className={styles.pageDesc}>
							We expect the DAO to distribute tokens to many Collab.Land
							contributors, members and communities on an ongoing basis.
						</p>
						<p className={styles.pageDesc}>
							The Collab.Land core team, investors, and partners have been
							allocated 25% of the total supply. This represents the time,
							energy and investment that they have put towards Collab.Land in
							the close to 3 years since the project was formed. They have a
							four-year time-based vesting schedule with a one-year cliff.
						</p>
					</div>
					<div className={styles.pictureContainer} style={{ paddingBottom: 0 }}>
						<img className={styles.mascot} src={token} alt="" />
					</div>
				</div>
				<br />
				<br />
				<br />
				<div className={styles.pictureTextContainer}>
					<div className={`${styles.textContainer} ${styles.pagePadding}`}>
						<p className={styles.pageDesc}>
							For the Retroactive Distribution, the breakdown of token
							distribution is as follows:
						</p>
						<p className={styles.pageDesc}>
							1. Top 100 Discord Communities = 60%
						</p>
						<p className={styles.pageDesc}>
							2. Collab.Land Patron NFT Holders (token numbers 0-141) = 16%
						</p>
						<p className={styles.pageDesc}>
							3. Collab.Land Membership NFT Holders = 16%
						</p>
						<p className={styles.pageDesc}>
							4. Verified Community Members as of 2/14/23 = 8%
						</p>
						<br />
						<p className={styles.pageDesc}>
							Any tokens that remain unclaimed on 5/23/23 will return to the DAO
							Treasury.
						</p>
					</div>
					<div className={styles.pictureContainer} style={{ paddingTop: 0 }}>
						<img className={styles.mascot} src={token2} alt="" />
					</div>
				</div>
			</div>
			<div className={`${styles.page} ${styles.pageFour}`}>
				<div className={styles.emojiContainer}>
					<img
						className={styles.mascot}
						style={{ paddingRight: '60px', paddingTop: '50px' }}
						width={200}
						src={lego}
						alt=""
					/>
				</div>
				<div className={styles.textContainer}>
					<div className={styles.pageTitle}>What is the Collab.Land DAO?</div>
					<div className={styles.pageDesc}>
						<p className={styles.pageDesc}>
							The Collab.Land DAO (legal entity: Collab.Land Co-op) is focused
							on building a trusted ecosystem to enable greater value-alignment
							and cooperation within web3 projects. Bringing together thousands
							of different communities under one roof and allowing them to work
							together to identify and build the most effective tools to
							coordinate.
						</p>
						<p className={styles.pageDesc}>
							The DAO is made up of the community of COLLAB token holders. Any
							token holder is welcome to join the{' '}
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.introLink}
								href="https://discord.com/invite/collabland"
							>
								Discord
							</a>{' '}
							and verify their membership with the Collab.Land bot.
						</p>
						<p className={styles.pageDesc}>
							The work of the Collab.Land Co-op happens in different ways. One
							of the DAO’s primary functions is to oversee the Collab.Land
							Marketplace. DAO members will curate and review new apps submitted
							to the Marketplace and make other governance decisions.
						</p>
						<p className={styles.pageDesc}>
							The Collab.Land DAO and $COLLAB will be launched on{' '}
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.introLink}
								href="https://www.optimism.io/"
							>
								Optimism
							</a>
							. To participate in DAO governance, $COLLAB holders will need to
							add the Optimism network to their wallet.
						</p>
						<p className={styles.pageDesc}>
							See how to use{' '}
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.introLink}
								href="https://help.optimism.io/hc/en-us/articles/4411903123483-How-do-I-add-the-Optimism-network-to-my-wallet-"
							>
								Optimism on Metamask
							</a>
							.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default IntroView;
