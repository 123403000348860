import useStore from '../../store';
import { getUserIdInfo } from '../../utils';
import styles from './UserInfo.module.scss';

const UserInfo = () => {
	const userId = useStore(state => state.userId);

	const { userIdLabel, source } = getUserIdInfo(userId);

	return (
		<div className={styles.container}>
			<div className={styles.title}>{userIdLabel}</div>
			<div className={styles.subtitle}>{source}</div>
		</div>
	);
};

export default UserInfo;
